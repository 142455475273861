import React from 'react';
import { Container } from 'react-bootstrap';

import users from '@/assets/icons/users-gold.svg';
import clinic from '@/assets/icons/clinic-gold.svg';
import tasks from '@/assets/icons/tasks-gold.svg';
import trophy from '@/assets/icons/trophy-gold.svg';
import star from '@/assets/icons/star-gold.svg';
import clsx from 'clsx';
import * as styles from './numbers.module.scss';
import { Button } from '@/components';
import { trackButtonClick } from '@/services/analytics';
import { buttonEvents, redesEFranquiasButtonNames } from '@/constants/analytics';
import useScrollToTop from '@/utils/useScrollTo';

const Numbers = () => {
  const scrollToTop = useScrollToTop();
  const handleClick = () => {
    trackButtonClick({
      event: buttonEvents.redesEFranquias,
      buttonName: redesEFranquiasButtonNames.redesEFranquiasBoxPreco,
    });

    scrollToTop();
  };

  return (
    <Container>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            Tenha uma visão simplificada da sua rede a partir de
            <span className={styles.price}>R$ 150,00</span>
          </h2>
          <Button variant="alternative-blue" onClick={() => handleClick()}>
            <span className={styles.text}>Solicite uma demonstração</span> (é grátis!)
          </Button>
        </div>
        <hr className={styles.divider} />

        <div className={styles.wrapper}>
          <div className={styles.item}>
            <img src={users} alt="Usuários ativos" className={styles.image} />
            <div>
              <span className={styles.symbol}>+</span>
              <span className={styles.number}>60 mil</span>
            </div>
            <p className={styles.text}>usuários ativos</p>
          </div>

          <div className={styles.item}>
            <img src={clinic} alt="clinicas e consultórios" className={styles.image} />
            <div>
              <span className={styles.symbol}>+</span>
              <span className={styles.number}>12 mil</span>
            </div>
            <p className={styles.text}>clínicas e consultórios</p>
          </div>

          <div className={styles.item}>
            <img src={star} alt="melhor nota em apps" className={styles.image} />
            <div>
              <span className={clsx(styles.number)}>4,9</span>
              <span className={clsx(styles.textSmall)}> de </span>
              <span className={clsx(styles.number)}>5</span>
            </div>
            <p className={clsx(styles.text)}>melhor nota em apps</p>
          </div>

          <div className={styles.item}>
            <img src={tasks} alt="pacientes cadastrados" className={styles.image} />
            <div>
              <span className={styles.symbol}>+</span>
              <span className={styles.number}>40 milhões</span>
            </div>
            <p className={styles.text}>pacientes cadastrados</p>
          </div>

          <div className={clsx(styles.item, styles.noAfter)}>
            <img src={trophy} alt="líder de mercado" className={styles.image} />
            <span className={styles.number}>O Simples Dental</span>
            <p className={styles.text}>é líder no mercado</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Numbers;
